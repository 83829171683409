import { Injectable } from "@angular/core";
interface Scripts {
  name: string;
  src?: string;
  content?: string;
}
export const ScriptStore: Scripts[] = [
  {
    name: "BLB",
    src: "https://www.blueletterbible.org/assets-v3/scripts/blbToolTip/BLB_ScriptTagger-min.js",
  },
];

declare let document: any;

@Injectable()
export class ScriptService {
  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: "Already Loaded" });
      } else {
        //load script
        const script = document.createElement("script");
        script.type = "text/javascript";
        const inlineScript = document.createTextNode(
          this.scripts[name]?.content
        );
        script.appendChild(inlineScript);

        if (this.scripts[name]?.src) {
          script.src = this.scripts[name]?.src;
        }

        if (script.readyState) {
          //IE
          script.onreadystatechange = () => {
            if (
              script.readyState === "loaded" ||
              script.readyState === "complete"
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: "Loaded" });
            }
          };
        } else {
          //Others
          script.onload = (a) => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: "Loaded" });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: "Loaded" });
        document.getElementsByTagName("head")[0].appendChild(script);
      }
    });
  }
}
