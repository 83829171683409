import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: "blog-nagivation-header",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./nagivation-header.component.html",
  styleUrl: "./nagivation-header.component.scss",
})
export class NagivationHeaderComponent {}
