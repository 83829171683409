<mat-drawer-container style="width: 100%; height: 100%" [hasBackdrop]="false">
  <mat-drawer style="width: 280px; position: fixed" #drawer [mode]="'side'" [autoFocus]="true">
    <mat-list>
      <mat-list-item lines="1" (click)="goHome(); drawer.close()">
        <fa-icon matListItemIcon [icon]="homeIcon" [size]="'lg'"></fa-icon>
        <span matListItemTitle>Welcome</span>
        <!-- <span matListItemLine>here is a welcome message</span> -->
      </mat-list-item>
      <mat-divider></mat-divider>

      <div mat-subheader>Truth</div>
      <mat-list-item lines="3" (click)="goGospel(); drawer.close()">
        <fa-icon matListItemIcon [icon]="gospelIcon" [size]="'lg'"></fa-icon>
        <span matListItemTitle>The Good News</span>
        <span>the everlasting covenant that does not change.</span>
      </mat-list-item>
      <mat-divider></mat-divider>

      <div mat-subheader>Posts</div>
      <mat-list-item lines="3" (click)="goPsalm(); drawer.close()">
        <fa-icon matListItemIcon [icon]="psalmIcon" [size]="'lg'"></fa-icon>
        <span matListItemTitle>Poem</span>
        <span>an exalting & magnifying of the Lord with poetry. </span>
      </mat-list-item>
      <mat-list-item lines="3" (click)="goInsight(); drawer.close()">
        <fa-icon matListItemIcon [icon]="insightIcon" [size]="'lg'"></fa-icon>
        <span matListItemTitle>Insight</span>
        <span>a collection of things understood in the world.</span>
      </mat-list-item>
      <mat-list-item lines="3" (click)="goBlog(); drawer.close()">
        <fa-icon matListItemIcon [icon]="blogIcon" [size]="'lg'"></fa-icon>
        <span matListItemTitle>Edification</span>
        <span>a set of scriptures knit together for encouraging.</span>
      </mat-list-item>
      <mat-list-item lines="3" (click)="goDiscern(); drawer.close()">
        <fa-icon matListItemIcon [icon]="discernIcon" [size]="'lg'"></fa-icon>
        <span matListItemTitle>Discern</span>
        <span>a set of judgements of different spirits & gospels.</span>
      </mat-list-item>
      <mat-divider></mat-divider>

      <div mat-subheader>Pictures</div>
      <mat-list-item lines="3" (click)="goDrawings(); drawer.close()">
        <fa-icon matListItemIcon [icon]="drawingIcon" [size]="'lg'"></fa-icon>
        <span matListItemTitle>Drawing</span>
        <span>making simple through contrast the truthful things.</span>
      </mat-list-item>
      <mat-list-item lines="3" (click)="goTracts(); drawer.close()">
        <fa-icon matListItemIcon [icon]="tractIcon" [size]="'lg'"></fa-icon>
        <span matListItemTitle>Tract</span>
        <span>letters to communicate truth in a quick & effective way.</span>
      </mat-list-item>

      <!-- <mat-divider></mat-divider>
      <div mat-subheader>Resources</div>
      <mat-list-item lines="3" (click)="goResources(); drawer.close()">
        <fa-icon matListItemIcon [icon]="resourceIcon" [size]="'lg'"></fa-icon>
        <span matListItemTitle>Link</span>
        <span>other proven links and websites and resources.</span>
      </mat-list-item> -->

      <mat-divider></mat-divider>
      <div mat-subheader>Contact</div>
      <mat-list-item lines="3" (click)="goEmail(); drawer.close()">
        <fa-icon matListItemIcon [icon]="mailIcon" [size]="'lg'"></fa-icon>
        <span matListItemTitle>Email</span>
        <span>be honest & contact me with things that come to mind.</span>
      </mat-list-item>
    </mat-list>
  </mat-drawer>
  <mat-drawer-content>
    <div class="header">
      <mat-toolbar color="primary">
        <button mat-icon-button class="example-icon" (click)="drawer.toggle()" title="menu-button">
          <fa-icon [icon]="icon" [size]="'lg'"></fa-icon>
        </button>

        <span></span>
      </mat-toolbar>
    </div>

    <router-outlet #toTop></router-outlet>

    <button class="scoll-to-top" mat-fab color="primary" style="z-index: 7777777" (click)="scrollToTop(toTop)">
      Top
    </button>

    <mat-progress-bar
      id="progress-bar"
      role="progressbar"
      title="Reading Progress"
      style="margin: 0"
      class="scoll-to-top"
      mode="determinate"
      [value]="progressValue$ | async"
    ></mat-progress-bar>
  </mat-drawer-content>
</mat-drawer-container>
